// COLORS
$primary-blue: rgb(52, 73, 94);
$medium-blue: rgb(108, 124, 141);
$light-blue: rgb(227, 236, 248);
$very-light-blue: rgb(244, 247, 251);
$secondary-blue: rgb(86, 180, 191);
$primary-yellow: rgb(255, 209, 103);
$hover-grey: rgb(242, 242, 242);
$background-grey: rgb(243, 243, 243);
$light-grey: rgb(224, 224, 224);
$medium-light-grey: rgb(203, 203, 203);
$medium-grey: rgb(183, 183, 183);
$medium-dark-grey: rgb(112, 112, 112);
$text-grey: rgb(86, 86, 86);
$dark-grey: rgb(57, 57, 57);
$error-red: rgb(209, 71, 85);

// SIZES
$left-panel-width: 300px;
$right-panel-width: 400px;
$mobile-navbar-height: 55px;
$mobile-panel-height: 50%;
$mobile-panel-min-height: 220px;
$header-height: 45px;

// RESPONSIVE
$mobile-max-width: 767px;


// EXPORTED VARIABLES
:export {
  mobileMaxWidth: $mobile-max-width;
  mobilePanelHeight: $mobile-panel-height;
  mobileNavbarHeight: $mobile-navbar-height;
}