.action {
  height: 15px;
  cursor: pointer;
  background-color: transparent;
  opacity: .85
}

.action:not(:last-child) {
  margin-right: 6px;
}

.action:hover {
  opacity: 1;
}

.actionIcon {
  height: 100%;
  width: 100%;
}
