@import './variables';

/* Style Scrollbar (Webkit only) */
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  background-color: transparent;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 1px solid transparent;
  background-clip: padding-box;
  background-color: rgba($primary-blue, .2);
}
