@use 'assets/css/variables';

.container {
  position: absolute;
	width: 100%;
  height: 100%;
  padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.logo {
	margin-bottom: 40px;
}

.title {
	margin-bottom: 60px;
	color: variables.$light-blue;
	font-size: 90px;
	font-weight: 600;
	text-align: center;
}

.text {
	margin-bottom: 40px;
	color: variables.$medium-blue;
	font-size: 23px;
  text-align: center;
}

.link {
	color: variables.$medium-blue;
	font-weight: 600;
	font-size: 16px;
}

/* MOBILE STYLES */
// @media (max-width: variables.$mobile-max-width) {
//   .container {
//     maxwidth: 95
//   }
// }