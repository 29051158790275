@use 'assets/css/variables';

$panel-separator: 7px;

.navbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: variables.$mobile-navbar-height;
  width: 100%;
  background-color: #fff;
  z-index: 20;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.75);
}

.navbar.open {
  box-shadow: none;
}

.separator {
  height: $panel-separator;
  background-color: variables.$light-blue;
}

.navItems {
  height: variables.$mobile-navbar-height - $panel-separator;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.logo {
  height: 38px;
}

.navButton {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
}

.navIcon {
  height: 95%;
  width: 95%;
}

.layersIcon {
  height: 105%;
  width: 105%;
}

.collaborateIcon {
  height: 120%;
  width: 120%;
  margin-bottom: 2px;
}

.panel {
  position: absolute;
  bottom: variables.$mobile-navbar-height;
  left: 0;
  right: 0;
  height: variables.$mobile-panel-height;
  min-height: variables.$mobile-panel-min-height;
  width: 100%;
  padding: 10px;
  overflow: scroll;
  z-index: 10;
  background-color: variables.$very-light-blue;
  box-shadow: 2px 0px 9px rgba(0, 0, 0, 0.75);
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transform: translateY(variables.$mobile-panel-height);
  transition: all .3s ease-out;
}

.panel.full {
  height: calc(100% - #{variables.$mobile-navbar-height});
  padding: 20px 15px;
}

.panel.open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.toolTitle {
  margin-bottom: 10px;
  color: variables.$primary-blue;
  font-size: 14px;
  font-weight: 600;
}
