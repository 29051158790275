@use 'assets/css/variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
}

.locationButton {
  height: 22px;
  width: 22px;
  margin-right: 6px;
  cursor: pointer;
  background-color: transparent;
}


.locationIcon {
  height: 100%;
  width: 100%;
  fill: variables.$primary-blue;
  opacity: .5;
}

.locationIcon:hover {
  opacity: .8;
}

.locationIcon.active {
  opacity: 1;
  fill: variables.$secondary-blue;
}

.locationIcon.selected {
  opacity: 1;
}

.message {
  color: variables.$primary-blue;
  font-size: 12px;
  opacity: .5;
}

.message.active {
  opacity: 1;
  color: variables.$secondary-blue;
}

.message.selected {
  opacity: 1;
}
