@use 'assets/css/variables';

.menuItem {
  width: 100%;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  color: variables.$text-grey;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.menuItem:hover {
  background-color: variables.$very-light-blue;
}

.menuItem.selected {
  background-color: variables.$medium-blue;
  color: #fff; 
}

.header {
  width: 100%;
  padding: 3px 5px;
  color: variables.$medium-blue;
  font-size: 13px;
  font-weight: 800;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}