@use 'assets/css/variables';
@use './ImageField.module.scss';

.imageContainer {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.image:hover {
  opacity: .6;
}

.deleteButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  cursor: pointer;
  transform-origin: right top;
  transition: transform .1s ease-out;
}

.deleteButton:hover {
  transform: scale(1.1);
}

.deleteIcon {
  height: 100%;
  fill: #fff;
}

.input {
  display: none;
}
