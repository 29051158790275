@use 'assets/css/variables';

.container {
  position: relative;
}

.avatarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, .25);
  opacity: .4;
  transform-origin: center center;
  transform: scale(1);
  transition: all .15s ease-out;
}

.avatarButton:hover {
  transform: scale(1.1);
}

.avatarButton.authenticated {
  opacity: 1;
}

.avatar {
  height: 100%;
  width: 100%;
}

.adminUserSubheader {
  font-size: 12px;
  font-weight: 300;
}

/* MOBILE STYLES */
@media (max-width: variables.$mobile-max-width) {
  .avatarButton {
    height: 32px;
    width: 32px;
  }
}
