@use 'assets/css/variables';
@use 'assets/css/common';

$company-header-height: 70px;
$separator-height: 10px;

.panel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: variables.$left-panel-width;
  z-index: 20;
  transform: translateX(-#{variables.$left-panel-width});
  transition: transform .3s ease-out;
}

.panel.open {
  transform: translateX(0);
}

.panelButton {
  position: absolute;
  top: 7px;
  left: calc(100% - 4px);
  height: 44px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: variables.$light-blue;
  opacity: .9;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
  z-index: -1;
  cursor: pointer;
  transform: translateX(0);
  transition: all .2s ease-out;
}

.panelButton.hover {
	opacity: 1;
  transform: translateX(3px);
}

.panelButton:focus {
	outline: none;
}

.triangleIcon {
  position: absolute;
  top: 13px;
  right: -2px;
	fill: variables.$primary-blue;
  height: 18px;
	width: 20px;
	opacity: .8;
	transform-origin: center center;
	transform: rotate(-90deg) translateY(1px);
	transition: transform .3s ease-out, opacity .1s ease-out;
}

.panelButton.open .triangleIcon {
  transform: rotate(90deg);
}

.panelButton.hover .triangleIcon {
	opacity: 1;
}

.panelMain {
  height: 100%;
  width: 100%;
  background-color: variables.$very-light-blue;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: opacity .4s ease-out;
}

.panel.open .panelMain {
  opacity: 1;
}

.panelHeader {
	background-color: #fff;
}

.companyHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $company-header-height;
  padding: 0 20px;
	color: variables.$medium-dark-grey;
	font-size: 15  px;
}

.logo {
  height: 35px;
}

.avatarContainer {
  position: relative;
}

.avatarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, .25);
  opacity: .4;
  transform-origin: center center;
  transform: scale(1);
  transition: all .15s ease-out;
}

.avatarButton:hover {
  transform: scale(1.1);
}

.avatarButton.authenticated {
  opacity: 1;
}

.avatar {
  height: 100%;
  width: 100%;
}

.adminUserSubheader {
  font-size: 12px;
  font-weight: 300;
}

.separator {
  height: $separator-height;
  background-color: variables.$light-blue;
}

.panelBody {
	@extend .scrollbar;
	height: calc(100% - #{$company-header-height + $separator-height});
	background-color: variables.$very-light-blue;	
	overflow-y: scroll;
}
