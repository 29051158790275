@use 'assets/css/variables';

.title {
  margin-bottom: 10px;
  color: variables.$primary-blue;
  font-size: 14px;
  font-weight: 600;
}

.content {
  min-height: 345px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  text-align: left;
}
