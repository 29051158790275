@use 'assets/css/variables';

.menuContainer {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .15);
  z-index: 50;
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s ease-out, opacity .2s ease-out;
}

.menuContainer.bottom {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 12px);
}

.menuContainer.top {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -12px);
}

.menuContainer.left {
  right: 100%;
  top: 50%;
  transform: translate(-12px, -50%);
}

.menuContainer.open {
  visibility: visible;
  opacity: 1;
}

.triangle {
  position: absolute;
  height: 10px;
  width: 10px;
  transform-origin: center center;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, .15);
  z-index: -1;
}

.menuContainer.bottom .triangle {
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.menuContainer.top .triangle {
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.menuContainer.left .triangle {
  top: 50%;
  right: -5px;
  transform: translateY(-50%) rotate(45deg);
}

.menu {
  padding: 2px;
  border-radius: 4px;
  background-color: #fff;
}
