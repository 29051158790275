@use 'assets/css/variables';
@use 'assets/css/common';

.layersLayout {
  padding: 25px 20px 20px;
}

.section {
  margin-bottom: 25px;
}

.section h2 {
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0 , 0, .16);
  text-transform: uppercase;
}

.group {
  width: 100%;
  border-radius: 5px;
  background-color: rgba(variables.$light-blue, .3);
  background-color: variables.$very-light-blue;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .25);
  cursor: pointer;
  transition: box-shadow .15s ease-out;
}

.group:not(:first-child) {
  margin-top: 20px;
}

.groupHeader {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 10px;
}

.groupHeaderIcon {
  height: 25px;
  width: 25px;
  margin-right: 7px;
  transform-origin: center center;
  transform: scaleY(.8);
  transition: transform .15s ease-out;
}

.group.expanded .groupHeaderIcon {
  transform: scaleY(1.15);
}

.groupHeaderName {
  color: variables.$medium-blue;
  font-size: 13px;
  opacity: .75;
  text-align: left;
  transition: opacity .15s ease-out;
}

.groupHeader:hover .groupHeaderName {
  opacity: 1;
}

.layer {
  position: relative;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
  cursor: pointer;
  transition: box-shadow .15s ease-out;
}

.layer:not(:first-child) {
  margin-top: 10px;
}

.layer:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, .25);
}

.layerBorder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: rgba(variables.$light-blue, 0.5);
}

.layerHeader {
  position: relative;
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 22px 0 10px;
}

.layerHeaderIcon {
  height: 25px;
  width: 25px;
  margin-right: 7px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform .15s ease-out;
}

.layer:hover .layerHeaderIcon {
  transform: scale(1.08);
}

.layerHeaderName {
  color: variables.$medium-grey;
  font-size: 13px;
  text-align: left;
  transition: color .15s ease-out;
}

.layer:hover .layerHeaderName {
  color: variables.$primary-blue;
}

.layerArrowButton {
  position: absolute;
  right: 4px;
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .15s ease-out;
}

.layerArrowButton:hover {
  background-color: variables.$medium-blue;;
}

.layerArrowIcon {
  height: 55%;
  width: 75%;
  padding-top: 1px;
  fill: variables.$medium-dark-grey;
  transform-origin: center center;
  transform: rotate(0);
  transition: all .15s ease-out;
}

.layerArrowButton:hover .layerArrowIcon {
  fill: #fff;
}

.layer.expanded .layerArrowIcon {
  transform: rotate(180deg);
}


/* MOBILE STYLES */
@media (max-width: variables.$mobile-max-width) {
  .layersLayout {
    padding: 10px;
  }

  .section {
    margin-bottom: 20px;
  }

  .section h2 {
    margin-bottom: 12px;
  }

  .group:not(:first-child) {
    margin-top: 15px;
  }

  .groupHeader {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 0 10px;
  }

  .layerHeader {
    height: 45px;
    padding: 0 25px 0 10px;
  }

  .layerArrowButton {
    height: 20px;
    width: 20px;
  }
}
