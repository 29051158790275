@use 'assets/css/variables';

.header {
  position: relative;
  margin-bottom: 25px;
  padding: 0 20px;
}

.title {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  color: variables.$primary-blue;
  text-align: center;
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
}

.spinner {
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 2px solid variables.$medium-blue;
  border-right-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spin .75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
