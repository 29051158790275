@use 'assets/css/variables';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .container.show {
  display: flex;
} */

.spinner {
  visibility: hidden;
  position: relative;
  animation: spinner 2.5s infinite linear both;
}

.spinner.show {
  visibility: visible;
}

.spinnerDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: spinnerDot 2.0s infinite ease-in-out both; 
}

.spinnerDot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: variables.$primary-blue;
  border-radius: 100%;
  animation: spinnerDotBefore 2.0s infinite ease-in-out both; 
}

.spinnerDot:nth-child(1) { animation-delay: -1.1s; }
.spinnerDot:nth-child(2) { animation-delay: -1.0s; }
.spinnerDot:nth-child(3) { animation-delay: -0.9s; }
.spinnerDot:nth-child(4) { animation-delay: -0.8s; }
.spinnerDot:nth-child(5) { animation-delay: -0.7s; }
.spinnerDot:nth-child(6) { animation-delay: -0.6s; }
.spinnerDot:nth-child(1):before { animation-delay: -1.1s; }
.spinnerDot:nth-child(2):before { animation-delay: -1.0s; }
.spinnerDot:nth-child(3):before { animation-delay: -0.9s; }
.spinnerDot:nth-child(4):before { animation-delay: -0.8s; }
.spinnerDot:nth-child(5):before { animation-delay: -0.7s; }
.spinnerDot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes spinner {
  100% { transform: rotate(360deg); } 
}

@keyframes spinnerDot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes spinnerDotBefore {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}