@use 'assets/css/variables';

.basemapControl {
  position: absolute;
  top: 157px;
  right: calc(100% + 10px);
}

.controlButton {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 29px;
	width: 29px;
	border-radius: 6px;
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.controlButton:hover {
	background-color: variables.$hover-grey;
}

.controlButton:focus {
	outline: none;
}

.controlIcon {
	width: 65%;
}

.menu {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	top: 15px;
	right: 29px;
	background-color: white;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.75);
	border-radius: 6px;
	padding: 2px;
	transition: all .2s ease-out;
}

.menu.open {
	visibility: visible;
	opacity: 1;
	transform: translateX(-13px);
}

.triangleIcon {
	position: absolute;
	top: 7px;
	right: -11px;
	height: 13px;
	fill: #fff;
  transform: rotate(-90deg);
}

.menuItem {
	padding: 5px 20px;
	border: 0;
	border-radius: 4px;
	font-size: 15px;
	text-align: center;
	cursor: pointer;
}

.menuItem:hover {
	background-color: variables.$very-light-blue;
}

.menuItem.selected {
	background-color: variables.$primary-blue;
	color: white;
}

.collaborateButton {
  position: absolute;
  bottom: 30px;
  right: calc(100% + 10px);
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform-origin: center center;
  transform: scale(1);
  transition: all .15s ease-out;
}

.collaborateButton:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
}

.collaborateIcon {
  height: 90%;
  width: 90%;
}

/* MOBILE STYLES */
@media (max-width: variables.$mobile-max-width) {
  .basemapControl {
    right: 10px;
  }

  .collaborateButton {
    display: none
  }
}
