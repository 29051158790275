@use 'assets/css/variables';
@use 'assets/css/common';

.legend {
  padding: 0 5px 5px 12.5px;
  text-align: left;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 9.5px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

.label {
  color: variables.$text-grey;
  color: variables.$medium-dark-grey;
  font-size: 10px;
}

.legendImage {
  max-width: 100%;
}
