@use 'assets/css/variables';

.button {
	display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 25px;
	padding: 0 10px;
  font-size: 12px;
	border-radius: 3px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
	font-size: 15px;
	cursor: pointer;
  transition: all .1s ease-out;
}

.button.small {
  padding: 0 10px;
  font-size: 12px;
}

.button.fullwidth {
  width: 100%;
}

.button.center {
  margin: 0 auto;
}

.button.primary {
  background-color: variables.$medium-blue;
  color: #fff;
  border: 1px solid variables.$medium-blue;
}

.button.primary:hover {
  opacity: .85;
}

.button.secondary {
  background-color: #fff;
  color: variables.$medium-blue;
  border: 1px solid rgba(variables.$medium-blue, .6);
}

.button.secondary:hover {
  background-color: variables.$very-light-blue;
}

.button.primary:disabled, .button.secondary:disabled {
  opacity: .3;
  cursor: auto;
}

.button.sending {
  color: transparent;
}

.button.sending::after {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  height: 16px;
  width: 16px;
  border: 2px solid white;
  border-right-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  animation: spin .75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
