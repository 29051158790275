@use 'assets/css/variables';
@use './FieldCommon.module.scss';

.selectContainer {
  position: relative;
}

.selectContainer::after {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  content: '';
  width: 15px;
  height: 10px;
  pointer-events: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(108, 124, 141)" preserveAspectRatio="none"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>');;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.select {
  @extend .input;
  padding-right: 22px;
  appearance: none;
  -webkit-appearance:none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  resize: none;
}

.select.invalid {
  color: transparent;
}

.select option {
  color: variables.$text-grey;
}

.select option:disabled {
  color: variables.$medium-grey;
}
