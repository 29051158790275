@use 'assets/css/common';
@use 'assets/css/variables';

.panelContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: variables.$right-panel-width;
  z-index: 20;
  transform: translateX(variables.$right-panel-width);
  transition: transform .25s ease-out;
}

.panelContainer.open {
  transform: translateX(0);
}

.panel {
  @extend .scrollbar;
  height: 100%;
  width: 100%;
  padding: 15px;
  background-color: variables.$very-light-blue;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.75);
  opacity: 0;
  text-align: center;
  overflow-y: scroll;
  transition: opacity .4s ease-out;
}

.panelContainer.open .panel {
  opacity: 1;
}
