@use 'assets/css/variables';

.background {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.35);
	z-index: 50;
}

.modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 350px;
	padding: 20px;
	border-radius: 5px;
	background-color: #fff;
}

.message {
	color: variables.$text-grey;
	font-size: 15px;
	text-align: center; 
}

.buttons {
	width: 80%;
	margin: 30px auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}