@use 'assets/css/variables';

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .25);
  z-index: 50;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  max-width: 100%;
  padding: 35px 25px;
  background-color: variables.$very-light-blue;
  border-radius: 7px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.title {
  margin-bottom: 20px;
	color: variables.$primary-blue;
	text-align: center;
	font-size: 15px;
  font-weight: 700;
}

.titleOption {
  cursor: pointer;
  transition: all .15s ease-out;
}

.titleOption:not(.selected) {
  font-weight: 400;
  opacity: .35;
}

.error {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: variables.$error-red;
  font-size: 12px;
}

.confirmation {
  color: variables.$primary-blue;
}

.formField {
  margin-bottom: 10px;
}

.fieldLabel {
  display: block;
  margin-bottom: 5px;
  color: variables.$medium-blue;
  font-size: 12px;
}

.input {
  height: 27px;
  width: 100%;
  padding: 0 5px;
  background-color: variables.$hover-grey;
  background-color: #fff;
  // background-color: variables.$light-grey;
  border: none;
  border-radius: 4px;
  // box-shadow: 0px 0px 3px variables.$medium-blue;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, .15);
  transition: box-shadow .2s ease-out;
}

.input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px variables.$medium-blue;
}

.link {
  display: block;
  margin: 0 auto;
  background-color: inherit;
  color: variables.$medium-blue;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 30px;
}

/* MOBILE STYLES */
@media (max-width: variables.$mobile-max-width) {
  .input {
    font-size: 16px;
  }
}