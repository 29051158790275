@use 'assets/css/variables';

.container {
  position: relative;
  margin-bottom: 12px;
}

.label {
  margin-bottom: 3px;
  color: rgba(variables.$primary-blue, .5);
  font-size: 9px;
  font-weight: 300;
  text-transform: uppercase;
}

.value {
  color: variables.$text-grey;
  font-size: 12px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.input {
  width: 100%;
  padding: 4px 5px;
  background-color: variables.$background-grey;
  color: variables.$text-grey;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, .15);
  font-size: 12px;
  resize: vertical;
  transition: box-shadow .2s ease-out;
}

.input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px variables.$medium-blue;
}

/* MOBILE STYLES */
@media (max-width: variables.$mobile-max-width) {
  .input {
    font-size: 16px;
  }
}
