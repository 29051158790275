@use '../../assets/css/variables';
@use '../../assets/css/common';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  overflow: hidden;
}

body {
  @extend .scrollbar;
}

button {
  font-family: Raleway, sans-serif;
}

* {
  box-sizing: border-box; 
}

h1, h2, p {
  font-size: 14px;
  margin: 0;
}

button, input, textarea, select {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  padding: 0;
  border: none;
  background-color: #fff;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: rgb(0, 128, 255);
  font-weight: 600;
}

/* MAPBOX */
.mapboxgl-canvas:focus {
  outline: none;
}

.mapboxgl-ctrl-top-right {
  transform: translateX(0);
  transition: all .25s ease-out;
}

.mapboxgl-ctrl-top-right.panelOpen {
  transform: translateX(-#{variables.$right-panel-width});
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  height: 29px;
  width: 29px;
  margin: 20px 10px 0 0;
  border-radius: 6px;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  min-width: 29px;
}

.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
  top: 4px;
  left: 4px;
  height: 22px;
  width: 22px;
  fill: variables.$primary-blue;
}

.mapboxgl-ctrl-geocoder--input {
  height: 29px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  display: block;
  margin: 7px 10px 20px 0;
  border-radius: 6px;
  box-shadow: none;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='#{variables.$primary-blue}' %3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='#{variables.$primary-blue}' %3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='#{variables.$primary-blue}' %3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E");
}

.marker {
  height: 27px;
  width: 27px;
  background-size: cover;
}

/* MOBILE STYLES */
// @media (max-width: 767px) {
//   .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
//     display: none;
//   }

//   .mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
//     display: block;
//     margin: 10px 10px 20px 0;
//   }
// }