@use 'assets/css/variables';

.header {
  margin-bottom: 15px;
  color: variables.$medium-blue;
  font-weight: 400;
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.button {
  height: 60px;
  width: 150px;
  color: variables.$medium-grey;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border-left: 5px solid transparent;
  cursor: pointer;
  transform-origin: center center;
  transform: scale(1);
  transition: all .15s ease-out;
}

.button:hover {
  color: variables.$medium-dark-grey;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.button.yellow:hover {
  border-left-color: rgb(237, 208, 108);
}

.button.green:hover {
  border-left-color: rgb(138, 171, 90);
}

.button.blue:hover {
  border-left-color: rgb(176, 198, 233);
}
