@use 'assets/css/variables';
@use './FieldCommon.module.scss';

.image {
  visibility: hidden;
  height: 100px;
  max-width: 100%;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(variables.$very-light-blue, .7);
}

.image.show {
  visibility: visible;
}

.noImage {
  cursor: auto;
}
