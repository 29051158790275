@use 'assets/css/variables';

.notification {
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translate(-50%, -15px);
  height: 70px;
  width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
  padding: 10px 20px;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 5px;
  font-size: 14px;
	text-align: center;
	z-index: 100;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	transition: all .25s ease-out;
}

.notification.show {
	visibility: visible;
	opacity: 1;
	transform: translate(-50%, 0);
}

.errorIcon {
	margin-right: 10px;
}

.message {
	text-align: left;
}

.message.error {
	color: variables.$error-red;
}
